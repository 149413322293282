export const HAIR_COLORS = [
  'Black',
  'Dark Brown',
  'Medium Brown',
  'Light Brown',
  'Dirty Blonde',
  'Blonde',
  'Platinum Blonde',
  'Red',
  'Auburn',
  'Strawberry Blonde',
  'Ginger',
  'Copper',
  'Gray',
  'Salt-and-Pepper',
  'White'
] as const;