import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  server: {
    GOOGLE_CLIENT_SECRET: z.string().min(1),
    NEXTAUTH_SECRET:
      process.env.NODE_ENV === "production"
        ? z.string().min(1)
        : z.string().min(1).optional(),
    NODE_ENV: z.enum(["development", "production"]).optional(),
    DATABASE_URL: z.string().url(),
    SENTRY_AUTH_TOKEN: z.string().min(1),
    SENTRY_DSN: z.string().url(),
    CLOUDFLARE_ACCOUNT: z.string().min(1),
    CLOUDFLARE_API_KEY: z.string().min(1),
    STRIPE_SECRET_KEY: z.string().min(1),
    STRIPE_SIGNING_SECRET: z.string().min(1),
    STRIPE_WEBHOOK_SECRET: z.string().min(1),
    AWS_ACCESS_KEY_ID: z.string().min(1),
    AWS_SECRET_ACCESS_KEY: z.string().min(1),
    AWS_REGION: z.string().min(1),
  },
  client: {
    NEXT_PUBLIC_GOOGLE_CLIENT_ID: z.string().min(1),
    NEXT_PUBLIC_VERCEL_ENV: z.string().min(1).optional().default("development"),
    NEXT_PUBLIC_GA_ID: z.string().min(1),
    NEXT_PUBLIC_SENTRY_DSN: z.string().url(),
    NEXT_PUBLIC_AMPLITUDE_API_KEY: z.string().min(1),
    NEXT_PUBLIC_PUSHER_CHANNEL_NAME: z.string().min(1),
    NEXT_PUBLIC_PUSHER_KEY: z.string().min(1),
    NEXT_PUBLIC_GOOGLE_ANALYTICS: z.string().min(1),
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string().min(1),
    NEXT_PUBLIC_APP_URL: z.string().url(),
    NEXT_PUBLIC_HOST: z.string().url(),
    NEXT_PUBLIC_IG_REDIRECT: z.string().url(),
    NEXT_PUBLIC_SUPABASE_URL: z.string().url(),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string().min(1),
    NEXT_PUBLIC_IG_CLIENT_ID: z.string().min(1),
    NEXT_PUBLIC_STATSIG_CLIENT_KEY: z.string().min(1),

    /**
     * Stripe products
     */
    /**
     * Initial purchases
     */
    NEXT_PUBLIC_STRIPE_STARTER_PRICE_ID: z.string().min(1),
    NEXT_PUBLIC_STRIPE_PRO_BUNDLE_PRICE_ID: z.string().min(1),
    NEXT_PUBLIC_STRIPE_PRO_PLUS_BUNDLE_PRICE_ID: z.string().min(1),
    /**
     * Add-ons
     */
    NEXT_PUBLIC_STRIPE_DO_OVER_PRICE_ID: z.string().min(1),
    NEXT_PUBLIC_STRIPE_SINGLE_PACK_PRICE_ID: z.string().min(1),
    NEXT_PUBLIC_STRIPE_THREE_PHOTO_PACK_BUNDLE_PRICE_ID: z.string().min(1),
    NEXT_PUBLIC_STRIPE_FIVE_PHOTO_PACK_BUNDLE_PRICE_ID: z.string().min(1),
    NEXT_PUBLIC_STRIPE_TEN_PHOTO_PACK_BUNDLE_PRICE_ID: z.string().min(1),
    /**
     * Subscription
    */
   NEXT_PUBLIC_STRIPE_PICSTUDIO_DRIVE_PRICE_ID: z.string().min(1),
    // Add other client-side variables here
  },
  experimental__runtimeEnv: {
    NEXT_PUBLIC_GOOGLE_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
    NEXT_PUBLIC_GA_ID: process.env.NEXT_PUBLIC_GA_ID,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
    NEXT_PUBLIC_PUSHER_CHANNEL_NAME: process.env.NEXT_PUBLIC_PUSHER_CHANNEL_NAME,
    NEXT_PUBLIC_PUSHER_KEY: process.env.NEXT_PUBLIC_PUSHER_KEY,
    NEXT_PUBLIC_GOOGLE_ANALYTICS: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    NEXT_PUBLIC_HOST: process.env.NEXT_PUBLIC_HOST,
    NEXT_PUBLIC_IG_REDIRECT: process.env.NEXT_PUBLIC_IG_REDIRECT,
    NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    NEXT_PUBLIC_IG_CLIENT_ID: process.env.NEXT_PUBLIC_IG_CLIENT_ID,
    NEXT_PUBLIC_STRIPE_STARTER_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_STARTER_PRICE_ID,
    NEXT_PUBLIC_STRIPE_PRO_BUNDLE_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_PRO_BUNDLE_PRICE_ID,
    NEXT_PUBLIC_STRIPE_SINGLE_PACK_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_SINGLE_PACK_PRICE_ID,
    NEXT_PUBLIC_STRIPE_THREE_PHOTO_PACK_BUNDLE_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_THREE_PHOTO_PACK_BUNDLE_PRICE_ID,
    NEXT_PUBLIC_STRIPE_FIVE_PHOTO_PACK_BUNDLE_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_FIVE_PHOTO_PACK_BUNDLE_PRICE_ID,
    NEXT_PUBLIC_STRIPE_TEN_PHOTO_PACK_BUNDLE_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_TEN_PHOTO_PACK_BUNDLE_PRICE_ID,
    NEXT_PUBLIC_STRIPE_PRO_PLUS_BUNDLE_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_PRO_PLUS_BUNDLE_PRICE_ID,
    NEXT_PUBLIC_STRIPE_DO_OVER_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_DO_OVER_PRICE_ID,
    NEXT_PUBLIC_STRIPE_PICSTUDIO_DRIVE_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_PICSTUDIO_DRIVE_PRICE_ID,
    NEXT_PUBLIC_STATSIG_CLIENT_KEY: process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY,
    // Add other runtime environment variables here
  },
  skipValidation:
    !!process.env.CI || process.env.npm_lifecycle_event === "lint",
});
