import { Album, Model, Theme } from '@acme/db';
  import { cuid } from './createCuid';
import {prisma} from '@acme/db/client';
import { getInputsForBatchCreate } from './getInputsForBatchCreate';
import { WEBHOOKS_BASE_URL } from './constants';
import { ALBUM_STATUS } from './status/albumStatus';
import axios from 'axios';
import { constructWorkflowV2 } from './constructWorkflowV2';
import { getDefaultWorkflowName, WorkflowsName } from './comfy/workflows';
import { AspectRatio, PhotoSize } from './types/photo';
import { PHOTO_STATUS } from './status/photoStatus';
import { sleep } from './sleep';

export const CONTAINERS_MODAL_API_WORKFLOW = {
  CONTAINER_A: 'https://prompthunt--picstudio-comfyui-comfyui-api.modal.run',
  CONTAINER_B: 'https://prompthunt--picstudio-comfyui-comfyuiinstanceb-api-instance-b.modal.run',
  CONTAINER_C: 'https://prompthunt--picstudio-comfyui-comfyuiinstancec-api-instance-c.modal.run',
  CONTAINER_D: 'https://prompthunt--picstudio-comfyui-comfyuiinstanced-api-instance-d.modal.run',
  CONTAINER_E: 'https://prompthunt--picstudio-comfyui-comfyuiinstancee-api-instance-e.modal.run',
};

export function getNextContainerKey(lastUsedKey: string | null): string {
  const keys = Object.keys(CONTAINERS_MODAL_API_WORKFLOW);
  if (!lastUsedKey) return keys[0]!;
  const currentIndex = keys.indexOf(lastUsedKey);
  return keys[(currentIndex + 1) % keys.length]!;
}

function getWorkflowName(
  theme: Theme,
  model: {
    version: '1.5' | 'flux.1-dev';
  },
  themesConfig?: { id: string; workflowName: string }[] | { id: string; workflowName: string }
) {
  if (themesConfig) {
    const themeConfig = Array.isArray(themesConfig)
      ? themesConfig.find(themeConfig => themeConfig.id === theme.id)
      : themesConfig;
    if (themeConfig) {
      return themeConfig.workflowName;
    }
  }
  return getDefaultWorkflowName(model.version as '1.5' | 'flux.1-dev');
}

export const processAlbumsAndCallModal = async (
  model: Model,
  themes: Theme[],
  album: Album,
  userId: string,
  themesConfig?:
    | {
        id: string;
        workflowName: string;
        aspectRatio: AspectRatio;
        size: PhotoSize;
        modifiers: { clothing: string } | undefined;
        qty?: number;
        photoQuantity?: number;
      }[]
    | {
        id: string;
        workflowName: string;
        aspectRatio: AspectRatio;
        size: PhotoSize;
        modifiers: { clothing: string } | undefined;
        qty?: number;
        photoQuantity?: number;
      }
) => {
  for (const theme of themes) {
    const themeConfig = Array.isArray(themesConfig)
      ? themesConfig.find(config => config.id === theme.id)
      : themesConfig;
    const quantity = themeConfig?.qty || 1;

    // Fetch the appropriate number of albums
    const albums = await prisma.album.findMany({
      where: {
        userId: userId,
        modelId: model.id,
        themeId: theme.id,
        status: ALBUM_STATUS.queued,
        id: album.id
      },
      orderBy: { createdAt: 'desc' },
      take: quantity
    });

    if (albums.length === 0) {
      console.error(`No albums found for theme ${theme.id}`);
      continue;
    }

    for (const album of albums) {
      const isChroma = false;

      console.log('Processing album:', album.id);
      console.log('isChroma:', isChroma);

      const inputs = await getInputsForBatchCreate(
        model,
        theme,
        isChroma,
        themeConfig?.aspectRatio! || '1:1',
        themeConfig?.size! || 'small',
        themeConfig?.modifiers as { clothing: string } | undefined,
        themeConfig?.photoQuantity
      );

      const inputsWithId = inputs.map(input => ({ ...input, id: cuid() }));

      await prisma.modelImage.createMany({
        data: inputsWithId.map((input, index) => {
          const themeWorkflowName = (theme.meta as { workflow_name?: string })?.workflow_name || getWorkflowName(
            theme,
            {
              version: model.version as '1.5' | 'flux.1-dev'
            },
            themesConfig
          );
          const workflowName = themeWorkflowName;
          return {
            id: input.id,
            imageUrl: '',
            watermarkImageUrl: '',
            themeId: theme.id,
            albumId: album.id,
            status: PHOTO_STATUS.queued,
            workflowName: workflowName,
            meta: {
              originalImage: undefined,
              upscaledImage: undefined,
              inputs: {
                name: `Image-${index}`,
                inputs: input.inputs
              },
            }
          };
        })
      });

      const imageWorkflows = [];

      for (let index = 0; index < inputsWithId.length; index++) {
        const input = inputsWithId[index];
        const themeWorkflowName = (theme.meta as { workflow_name?: string })?.workflow_name || getWorkflowName(
          theme,
          {
            version: model.version as '1.5' | 'flux.1-dev'
          },
          themesConfig
        );
        const workflowName = themeWorkflowName as WorkflowsName;

        console.log('The workflowName is:', workflowName);

        const workflow = constructWorkflowV2(workflowName, {
          ...input!.inputs,
          ckpt_name: model.storageName?.endsWith('.safetensors')
            ? model.storageName
            : `${model.storageName}.safetensors`,
          picstudio_model_id: model.id,
          guidance_scale: 6,
          num_inference_steps: 40,
          disable_safety_check: true,
          num_outputs: 1
        });

        imageWorkflows.push({
          model_image_id: input!.id,
          workflow: { ...workflow },
          provider_name: 'modal',
          provider_gpu: 'A100'
        });

        const requestBody = {
          webhook_url: `${WEBHOOKS_BASE_URL}/api/model/${model.id}/album/${album.id}/modal/webhook`,
          workflow_name: workflowName,
          identifier: input!.id,
          model_id: model.id,
          model_name: model.storageName,
          input: {
            prompt: input!.inputs.prompt,
            negative_prompt: input!.inputs.negative_prompt,
            width: input!.inputs.width,
            height: input!.inputs.height,
            guidance_scale: 6,
            scheduler: 'karras',
            denoise: 1,
            seed: input!.inputs.ksampler_seed,
            sampler: 'dpmpp_sde',
            steps: 40
          },
          index: index,
          workflow: workflow
        };

        try {
          console.log('Calling Modal Container : ', album.containerKey);
          axios.post(
            CONTAINERS_MODAL_API_WORKFLOW[album.containerKey as keyof typeof CONTAINERS_MODAL_API_WORKFLOW] ||
              CONTAINERS_MODAL_API_WORKFLOW.CONTAINER_A,
            requestBody,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
        } catch (error) {
          console.error(`Error calling Modal API for input ${input!.id}:`, error);
        }
      }

      // Create image workflows
      await prisma.imageWorkflow.createMany({
        data: imageWorkflows
      });

      await sleep(1000);
    }
  }
};
