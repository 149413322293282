import { parseEthnicity } from './ethnicity';
// import { parseBodyType } from './bodyType';
import { getGenderDescriptor } from './gender';
import { getAgeDescription } from './age';
import { getGlassesDescription } from './glasses';
import { getHairDescription, getHairLengthDescription } from './hair';
import { removeExtraSpaces } from '../removeExtraSpaces';

const getFacialHairDescription = (gender: string, facialHair?: boolean, facialHairLength?: string, facialHairColor?: string, facialHairStyle?: string): string => {
  if (gender !== 'Male' || !facialHairStyle) return '';

  if(typeof facialHair === 'boolean' && facialHair === false) {
    return 'and clean shaven';
  }
  
  if (facialHairStyle === 'None') {
    return 'and clean shaven';
  }
  
  return `and ${facialHairStyle.toLowerCase()} ${facialHairLength?.toLowerCase()} ${facialHairColor?.toLowerCase()}`;
};

export const generateModelDescription = (attributes: PrismaJson.ModelAttributes, {
  // enabledAge = false,
  modifiers = {}
}: {
  enabledAge?: boolean;
  modifiers?: { [key: string]: string } | undefined,
}): string => {
  const gender = attributes?.gender === 'Male' ? 'man' : 'woman';
  const genderDescriptor = getGenderDescriptor(attributes?.gender || '');
  const age = getAgeDescription(attributes?.age || '');

  /**
   * Generate the ethnicity description, if available. Otherwise, use an empty string.
   */
  const ethnicity = parseEthnicity(attributes?.ethnicity || '', !attributes?.ethnicity).trim();

  /**
   * Replace the {body type} placeholder with the actual body type, if available. Otherwise, replace it with an empty string.
   */
  // const bodyType = trainingMeta?.bodyType ? parseBodyType(trainingMeta.bodyType, trainingMeta.gender as 'Male' | 'Female').trim() : '';

  /**
   * Replace the {hair length} placeholder with the actual hair length, if available. Otherwise, replace it with an empty string.
   */
  const hair = getHairDescription(attributes?.hair, attributes?.hairColor || '', attributes?.hairLength || '', attributes?.hairTexture || '', attributes?.hairStyle || '')

  /**
   * Replace the {glasses} placeholder, if the model has the glasses attribute set to true. Otherwise, replace it with an empty string.
   */
  const glasses = getGlassesDescription(attributes?.glasses || false, modifiers.glasses);

  const facialHair = attributes?.facialHair ? getFacialHairDescription(attributes?.gender || '', attributes?.facialHair, attributes?.facialHairLength || '', attributes?.facialHairColor || '', attributes?.facialHairStyle || '') : '';

  const isNewVersion = attributes.skinTone && attributes.hairLength && attributes.hairTexture;
  if (!isNewVersion) {
    // Get hair length description based on whether the model has hair:
    // - If attributes.hair is a boolean and true, use the provided hairLength (or empty string if undefined)
    // - If attributes.hair is false, default to 'Bald'
    const hairLength = getHairLengthDescription(typeof attributes.hair === 'boolean' && attributes.hair ? attributes.hairLength || '' : 'Bald');
    const skinTone = attributes.skinTone ? `with ${attributes.skinTone} skin tone` : '';
    return `${genderDescriptor} ${ethnicity} TOK ${gender} model ${hairLength} ${glasses} ${facialHair} ${skinTone}`.trim();
  }

  return removeExtraSpaces(
    `${age} ${ethnicity} TOK ${gender} with ${attributes?.eyeColor} eyes ${glasses} and ${hair} ${facialHair} and ${attributes?.skinTone} skin tone`.trim()
  );
  // 38 year old Caucasian TOK man with Blue eyes wearing glasses and Medium Medium Brown Straight Combed hair and clean shaven and Fair skin tone, looking at camera, eyes open
};

