
import { Theme } from '@acme/db';
import { getPromptFromTheme } from './getPromptFromTheme';
import { processAndGeneratePrompt } from './processPromptTriggers';

interface GetPromptWithTriggersProps {
  theme: Theme;
  attributes: PrismaJson.ModelAttributes;
  count: number;
  modifiers?: { [key: string]: string } | undefined;
  enabledAge?: boolean;
}

export const getPromptWithTriggers = async ({ theme, attributes, count, modifiers, enabledAge  }: GetPromptWithTriggersProps) => {
  let { prompt: initialPrompt, negativePrompt: initialNegativePrompt } = getPromptFromTheme(theme, '');

  let { prompts, negativePrompts } = await processAndGeneratePrompt(
    initialPrompt,
    initialNegativePrompt,
    attributes,
    count,
    modifiers,
    enabledAge
  );

  return { prompts, negativePrompts };
};
