export const HAIR_STYLES = [
  'My Hairstyle',
  'Buzz Cut',
  'Crew Cut',
  'Classic Pixie Cut',
  'Caesar Cut',
  'Bob',
  'Side-Part',
  'Fringe',
  'Fade',
  'Textured Top',
  'Side Part',
  'Ivy League Cut',
  'Neat Comb-Over',
  'Blunt Cut',
  'Center Part',
  'Loose Ponytail',
  'Low Bun',
  'Half-Up Ponytail',
  'Simple Braid',
  'Slicked-Back'
] as const;


export type HairStyle = (typeof HAIR_STYLES)[number];
