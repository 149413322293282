import { useStatsigClient } from '@statsig/react-bindings';
import { EventMap } from './EventMap';
import { getStoredCampaignParams } from './AnalyticsStatsigProvider';
import { sendGTMEvent } from '@next/third-parties/google';

// statsig metadata is limited to strings, so we need to convert the values to strings
function parseMetadata(metadata: Record<string, number | string | undefined> | undefined) {
  return Object.entries(metadata || {}).reduce<Record<string, string>>((acc, [key, value]) => {
    acc[key] = typeof value === 'number' ? value.toString() : value || '';
    return acc;
  }, {});
}

export function useTrackEvent() {
  const { client } = useStatsigClient();

  function trackEvent<K extends keyof EventMap>(event: K, properties: EventMap[K]) {
    let metadata = properties.metadata ? parseMetadata(properties.metadata) : undefined;
    let campaignParams = {};

    // For purchase events, attach campaign parameters
    if (event === 'purchase_event') {
      campaignParams = getStoredCampaignParams();
      metadata = {
        ...metadata,
        ...campaignParams
      };
    }

    // Send event to Statsig
    client.logEvent(event, properties.value ? properties.value : undefined, metadata);

    // Send event to GTM
    // Format the GTM event data based on the event type
    const gtmEventData = {
      event,
      ...metadata,
      value: properties.value
    };

    // Special handling for specific event types
    switch (event) {
      case 'purchase_event':
        // *WARNING*: WE have this event setup in the checkout page, so we need to keep it consistent,
        // for now, we are removing the this abstraction
        // Keep consistent with the checkout page GTM event format
        // sendGTMEvent({
        //   event,
        //   value: metadata?.productName,
        //   price: metadata?.price,
        //   purchaseType: metadata?.purchaseType,
        //   transactionId: metadata?.transactionId,
        //   ...campaignParams
        // });
        break;

      case 'initiateCheckout':
        // *WARNING*: WE have this event setup in the pricing page, so we need to keep it consistent,
        // for now, we are removing the this abstraction
        // Keep consistent with the checkout page GTM event format
        // sendGTMEvent({
        //   event,
        //   value: metadata?.productName,
        //   price: metadata?.price,
        //   purchaseType: metadata?.purchaseType,
        //   transactionId: metadata?.transactionId,
        //   ...campaignParams
        // });
        break;

      // For all other events, send the standard format
      default:
        sendGTMEvent(gtmEventData);
        break;
    }
  }

  return { trackEvent };
}
