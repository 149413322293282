import { randomUUID } from "crypto";
import { AwsClient } from "aws4fetch";

export type OutputImage = {
  filename: string;
  image_url: string;
  step?: number;
  pipeline?: string;
};

export async function uploadToPublicCloudflareBucket(imageUrl: string, originalFilename: string): Promise<OutputImage> {
  try {
    // Fetch the image
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText} (${response.status})`);
    }
    const buffer = await response.arrayBuffer();

    // Generate a unique filename using the original filename as prefix
    // Remove any potential unsafe characters from original filename
    const safeOriginalName = originalFilename.replace(/[^a-zA-Z0-9-_]/g, '_');
    const filename = `${safeOriginalName}_${randomUUID()}.png`;

    // Create R2 client
    const client = new AwsClient({
      accessKeyId: process.env.R2_ACCESS_KEY_ID!,
      secretAccessKey: process.env.R2_SECRET_ACCESS_KEY!
    });

    const R2_URL = `https://${process.env.CLOUDFLARE_ACCOUNT}.r2.cloudflarestorage.com`;

    // Create the request using the Request constructor instead of client.fetch
    const request = new Request(`${R2_URL}/picstudio-assets/${filename}`, {
      method: 'PUT',
      body: buffer,  // Use the buffer directly, no need for Buffer.from()
      headers: {
        'Content-Type': 'image/png',
        'Content-Length': buffer.byteLength.toString()
      }
    });

    // Sign and send the request
    const signedRequest = await client.sign(request);
    const uploadResponse = await fetch(signedRequest);

    if (!uploadResponse.ok) {
      throw new Error(`Failed to upload to R2: ${uploadResponse.statusText}`);
    }

    // Return the public URL
    const publicUrl = `https://pub-8757c63d2767440fa765d57c78202cf2.r2.dev/${filename}`;

    return {
      image_url: publicUrl,
      filename
    };
  } catch (error) {
    console.error('Error in uploadToPublicBucket:', error);
    throw error;
  }
} 