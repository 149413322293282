
import { processPrompt } from './processPrompt';
import { generatePrompts } from './generatePrompts';
import '@acme/db/types';
export interface ProcessedPrompts {
  prompts: string[];
  negativePrompts: string[];
}

export const processAndGeneratePrompt = async (
  _prompt: string,
  _negativePrompt: string,
  attributes: PrismaJson.ModelAttributes,
  count: number,
  modifiers?: { [key: string]: string } | undefined,
  _enabledAge?: boolean
): Promise<ProcessedPrompts> => {

  const { prompt, negativePrompt, categories } = await processPrompt(_prompt, _negativePrompt, attributes, modifiers);

  return generatePrompts(prompt, negativePrompt, count, categories, modifiers);
};

